import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoute, RestrictedRoute } from './Router';
import Login from './features/Login';
import { GlobalStyle } from './GlobalStyles'
import { Wrapper } from './App.styles';
import Site from 'features/Site';
import Order from 'features/Order';
import SiteNew from 'features/SiteNew';
import Common from 'Common';
import Cart from 'features/Cart';
import UserInfo from 'features/UserInfo';
import { Goods } from 'features/Goods';
import ImgPost from 'features/ImgPost';
import OrderDetails from 'features/Order/OrderDetails';
import { MorienShopSearch } from 'features/MorienShopSearch';
import { Bargain } from 'features/Bargain';
import Menu from 'features/Menu';
import SiteImage from 'features/SiteImage';

function App() {
    return (
        <Wrapper>
            <BrowserRouter basename={Common.BASE_NAME}>
                <Routes>
                    <Route element={<RestrictedRoute />}>
                        <Route path='/login' element={<Login />} />
                        <Route path='/morien-shop' element={<MorienShopSearch />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        <Route path='/' element={<Menu />} />
                        <Route path='/site' element={<Site />} />
                        <Route path='/site-image' element={<SiteImage />} />
                        <Route path='/goods' element={<Goods />} />
                        <Route path='/goods-all' element={<Goods all />} />
                        <Route path='/cart' element={<Cart />} />
                        <Route path='/site-new' element={<SiteNew />} />
                        <Route path='/order' element={<Order />} />
                        <Route path='/order/:id' element={<OrderDetails />} />
                        <Route path='/user' element={<UserInfo />} />
                        <Route path='/img-post' element={<ImgPost />} />
                        <Route path='/bargain' element={<Bargain />} />
                    </Route>
                    {/* <Route path="*" element={<NotFound />} /> */}
                </Routes>
                <GlobalStyle />
            </BrowserRouter>
        </Wrapper>
    );
}

export default App;
