import React, { useEffect, useRef, useState } from "react";
import HeaderBox from "components/HeaderBox";
import Loading from "components/Loading";
import SiteItem from "./SiteItem";
import { Wrapper, Content, DataEmpty } from './styles'
import { useSite } from "./useSite";
import SearchBox from "components/SearchBox";
import { TermSite } from "components/TermSearch";
import { useSiteDetails } from "./useSiteDetails";
import SiteDetails from "./SiteDetails";
import ButtonBack from "components/ButtonBack";
import SiteEdit from "./SiteEdit";
import MessageBox from "components/MessageBox";
import siteApi from "api/siteApi";
import { MESSAGE } from "Common";

const Site = () => {
    const { termSearch, setTermSearch, siteList, isLoading, setIsLoading, message, setMessage, reload, setReload } = useSite();
    const { isLoading: isLoading1, handleSelectSite, handleBackSiteList, objSite, setObjSite, salesGoodsList, totalPrice } = useSiteDetails();

    const [isEdit, setIsEdit] = useState(false);
    const refTop = useRef();


    const handleSave = async (siteName,
        siteKana,
        dateBeginning,
        dateFinished,
        postalCode,
        siteAddr,
        tel,
        area,
        siteMemo) => {

        try {
            //validate
            let msg = '';
            const dateStart = new Date(dateBeginning);
            const dateEnd = new Date(dateFinished);
            //SiteName
            if (!siteName.trim()) {
                msg = '現場名は入力必須項目です。';
            } else if (dateStart >= dateEnd) {
                msg = '期間指定開始日または最後日の日付範囲が正しくありません';
            }
            if (msg) {
                const _msg = {
                    contents: [msg],
                    type: MESSAGE.ERROR
                }
                setMessage(_msg);
                return;
            }

            setIsLoading(true);
            const params = {
                sID: objSite.SiteID,
                sN: siteName,
                sK: siteKana,
                dB: dateBeginning,
                dF: dateFinished,
                pC: postalCode,
                sA: siteAddr,
                tel: tel,
                area: area,
                sM: siteMemo
            }

            const dtResponse = await siteApi.updateSite(params);
            const dtSite = dtResponse?.data['dtSite'] ?? '';
            if (!dtSite || dtSite.length !== 1) {
                throw Error("更新が失敗しました。");
            }
            setReload(!reload);
            setObjSite(dtSite[0]);
            setIsEdit(false);
            setIsLoading(false);

        } catch (err) {
            const _msg = {
                contents: [err],
                type: MESSAGE.ERROR
            }
            setMessage(_msg);
            setIsLoading(false);
        }
    }
    const deleteSite = async () => {
        try {
            setIsLoading(true);
            const params = {
                sID: objSite.SiteID
            }
            const dtResponse = await siteApi.deleteSite(params);
            setReload(!reload);
            setObjSite("");
            setIsEdit(false);
            setIsLoading(false);

        } catch (err) {
            const _msg = {
                contents: ["データ現場があるので、削除できません。\n　モリエンの社員を連絡してください。"],
                type: MESSAGE.ERROR
            }
            setMessage(_msg);
            setIsLoading(false);
        }
    }



    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [siteList, refTop])


    return (
        <Wrapper>
            <Loading isLoading={isLoading || isLoading1} />
            <HeaderBox title={objSite.SiteID > 0 ? isEdit ? "現場編集" : "現場明細" : "現場検索"} haveCart />
            <div style={{ position: "relative" }}>
                <SearchBox setTermSearch={setTermSearch} placeholder={"現場名"}>
                    <TermSite setTermSearch={setTermSearch} />
                </SearchBox>
                <Content ref={refTop}>
                    {
                        siteList.length > 0 ? siteList.map((item) => {
                            return (
                                <SiteItem key={item.SiteID} item={item} onSelectSite={handleSelectSite} />
                            );
                        }) : <DataEmpty>データなし</DataEmpty>
                    }
                </Content>
                {objSite?.SiteID > 0
                    && <SiteDetails objSite={objSite}
                        totalPrice={totalPrice}
                        salesGoodsList={salesGoodsList}
                        handleDelete={() => {
                            if (objSite?.SiteDataExist > 0) {
                                const _msg = {
                                    contents: ["現場がすでに使用されているため、削除できません。",
                                        "削除したい場合は、モリエンまでご連絡ください。"],
                                    type: MESSAGE.INFO,
                                }
                                setMessage(_msg);
                                return;
                            }
                            const _msg = {
                                contents: ["削除しますか？"],
                                type: MESSAGE.WARNING,
                                isYesNo: true,
                                handleYes: () => { deleteSite() },
                            }
                            setMessage(_msg);
                        }}
                        handleEdit={() => { setIsEdit(true); }} />}
                {objSite?.SiteID > 0 && isEdit
                    && <SiteEdit objSite={objSite}
                        handleSave={handleSave}
                        handleBack={() => { setIsEdit(false) }}
                        setIsLoading={setIsLoading} />}
                {objSite?.SiteID > 0 && <ButtonBack onHandleBack={() => { if (!isEdit) { handleBackSiteList(); } else { setIsEdit(false); } }} />}
            </div>
            <MessageBox message={message} setMessage={setMessage} />
        </Wrapper>
    )
}

export default Site;