import React, { useState } from "react";
import JapanPostalCode from "japan-postal-code";
import { Wrapper, Content, Row, MLabel, InputText, Line, InputDate, TextArea, Button, Footer, InputNumber } from './styles'
import PostalCodeSelectBox from "components/PostalCodeSelectBox";
import MessageBox from "components/MessageBox";
import { MESSAGE } from "Common";
import postalCodeApi from "api/postalCodeApi";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import dayjs from "dayjs";
import { Stack } from "@mui/material";
import siteApi from "api/siteApi";


const SiteEdit = ({ objSite, handleSave, handleBack, setIsLoading, setMessage }) => {
    const dNowStr = dayjs().format('YYYY-MM-DD');
    const [siteName, setSiteName] = useState(objSite.SiteName);
    const [siteKana, setSiteKana] = useState(objSite.SiteKana);
    const [dateBeginning, setDateBeginning] = useState(dayjs(objSite.DateBeginning).format('YYYY-MM-DD'));
    const [dateFinished, setDateFinished] = useState(dayjs(objSite.DateFinished).format('YYYY-MM-DD'));
    const [postalCode, setPostalCode] = useState(objSite.PostalCode);
    const [siteAddr, setSiteAddr] = useState(objSite.AddressPrefecture + objSite.AddressCity + objSite.AddressNumber);
    const [tel, setTel] = useState(objSite.TEL);
    const [area, setArea] = useState(objSite.Area);
    const [siteMemo, setSiteMemo] = useState(objSite.ClientMemo);
    const [showGetPostalCode, setShowGetPostalCode] = useState(false);
    const [adrList, setAdrList] = useState([]);




    const handlePostCode = () => {
        if (!postalCode) return;
        JapanPostalCode.get(postalCode, (addr) => {
            setSiteAddr(`${addr.prefecture} ${addr.city}${addr.area}`);
        })

    }

    const handleGetPostalCode = async () => {
        //validate siteAddr
        if (!siteAddr) {
            const msg = { type: MESSAGE.ERROR, contents: ['住所を入力してください'] };
            setMessage(msg);
            return;
        }
        //get postal code
        try {
            setIsLoading(true);
            const { data } = await postalCodeApi.getPostalCode({ adr: siteAddr });
            const dtPostalCode = data?.dtPostalCode ?? [];
            if (dtPostalCode.length === 0) {
                const msg = { type: MESSAGE.ERROR, contents: ['該当する住所が見つかりません'] };
                setMessage(msg);
            } else if (dtPostalCode.length === 1) {
                setPostalCode(dtPostalCode[0].PostalCode);
            } else {
                setAdrList(dtPostalCode);
                setShowGetPostalCode(true);
            }

        } catch (error) {
            const msg = { type: MESSAGE.ERROR, contents: [error.toString()] };
            setMessage(msg);

        } finally {
            setIsLoading(false);
        }
    }


    return (
        <Wrapper>
            <Content>
                <Row column>
                    <MLabel>現場名：</MLabel>
                    <InputText
                        maxLength={100}
                        textHolder={'現場名'}
                        value={siteName}
                        onChange={(e) => { setSiteName(e.target.value) }}
                    />
                </Row>
                <Row column>
                    <MLabel>カナ：</MLabel>
                    <InputText
                        maxLength={100}
                        textHolder={'カナ'}
                        value={siteKana}
                        onChange={(e) => { setSiteKana(e.target.value) }}
                    />

                </Row>
                <Row column>
                    <MLabel>開始日～最後日：</MLabel>
                    <Line>
                        <InputDate
                            min={dNowStr}
                            max={'2099-12-31'}
                            value={dateBeginning}
                            onChange={(e) => { setDateBeginning(e.target.value); }}
                        />
                        ～
                        <InputDate
                            min={dNowStr}
                            max={'2099-12-31'}
                            value={dateFinished}
                            onChange={(e) => { setDateFinished(e.target.value); }}
                        />
                    </Line>
                </Row>
                <Row column>
                    <MLabel>郵便番号：</MLabel>
                    <Line>
                        <InputText
                            width={'100px'}
                            maxLength={10}
                            value={postalCode}
                            onChange={(e) => { setPostalCode(e.target.value); }}
                        />
                        <Button onClick={handlePostCode}>住所入力</Button>
                        <Button onClick={handleGetPostalCode}>郵便番号検索</Button>
                    </Line>
                </Row>
                <Row column>
                    <MLabel>住所：</MLabel>
                    <TextArea
                        maxLength={200}
                        rows={2}
                        value={siteAddr}
                        onChange={(e) => { setSiteAddr(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>ＴＥＬ：</MLabel>
                    <InputText
                        width={'150px'}
                        maxLength={50}
                        value={tel}
                        onChange={(e) => { setTel(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>面積：</MLabel>
                    <InputNumber
                        width={'100px'}
                        maxLength={10}
                        value={area}
                        onChange={(e) => { setArea(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>メモ(現場詳細、配達の際の注意事項があればご記入下さい)：</MLabel>
                    <TextArea
                        maxLength={300}
                        rows={4}
                        value={siteMemo}
                        onChange={(e) => { setSiteMemo(e.target.value); }}
                    />
                </Row>
                <Stack direction="row" justifyContent="right" mt={2} mr={2} spacing={2}>
                    <Button onClick={handleBack}>戻る</Button>
                    <Button onClick={() => {
                        handleSave(siteName,
                            siteKana,
                            dateBeginning,
                            dateFinished,
                            postalCode,
                            siteAddr,
                            tel,
                            area,
                            siteMemo)
                    }}>保存</Button>
                </Stack>
            </Content>
            <PostalCodeSelectBox show={showGetPostalCode}
                setShow={setShowGetPostalCode}
                resultList={adrList}
                renderItem={(item) => { return `${item.Pref} ${item.City}${item.Number}` }}
                selectItem={(item) => { setPostalCode(item.PostalCode); setSiteAddr(`${item.Pref} ${item.City}${item.Number}`) }} />
        </Wrapper>
    )
}

export default SiteEdit;