import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material';
import siteApi from 'api/siteApi';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const ModalShowImage = ({ siteImgID, setIsLoading, handleClose, handleDelete }) => {

  const [siteImg, setSiteImg] = useState();
  const [imgTitle, setImgTitle] = useState("");
  const [imgDate, setImgDate] = useState("");
  const [imgMemo, setImgMemo] = useState("");
  const [editImgTitle, setEditImgTitle] = useState(false);
  const [editImgDate, setEditImgDate] = useState(false);
  const [editImgMemo, setEditImgMemo] = useState(false);

  useEffect(() => {
    (async function getSiteImg() {
      if (!siteImgID) {
        setSiteImg(null);
        return
      }

      try {
        setIsLoading(true);
        const params = {
          imgID: siteImgID
        }
        const { data } = await siteApi.getSiteImageDetails(params);
        console.log(data);

        if (!data || !data["siteImageDetails"]) {
          throw new Error("テーだ")
        };
        const objSiteImg = data["siteImageDetails"];
        setImgTitle(objSiteImg.ImageTitle);
        setImgDate(dayjs(objSiteImg.ImageDate).format("YYYY-MM-DD"));
        setImgMemo(objSiteImg.ImageMemo);
        setSiteImg(data["siteImageDetails"]);
      } catch (error) {
        //TODO: show Error
      } finally {
        setIsLoading(false);
      }
    })()
  }, [siteImgID, setIsLoading]);

  const handleChange = async () => {
    if (!siteImg?.SiteImageID) {
      setEditImgTitle(false);
      return
    }

    try {
      setIsLoading(true);
      const params = {
        iId: siteImgID,
        iT: imgTitle.trim(),
        iD: imgDate.trim(),
        iM: imgMemo.trim()
      }
      const { data } = await siteApi.updateSiteImages(params);
      if (!data || !data["imgSiteID"]) {
        throw new Error("テーだ")
      };
      setSiteImg(preVal => ({
        ...preVal, ImageTitle: imgTitle.trim(),
        ImageDate: imgDate.trim(),
        ImageMemo: imgMemo.trim()
      }));
      setEditImgTitle(false);
      setEditImgDate(false);
      setEditImgMemo(false);
    } catch (error) {
      //TODO: show Error
    } finally {
      setIsLoading(false);
    }
  }


  return <>
    {!!siteImgID && !!siteImg && (
      <Dialog open={!!siteImg} onClose={() => { }} fullWidth >
        <DialogContent sx={{ py: 2, px: 1, minHeight: "55vh" }}>
          <Box sx={{ textAlign: "center" }} >
            <Box component={"img"} sx={{
              width: "70vw",
              maxWidth: "550px",
            }} alt='現場メイン画像' src={siteImg.ImageUrl} />
          </Box>
          <Stack direction="row" alignItems="end" mt={2}>
            <Typography variant='body2' sx={{ minWidth: "65px" }}>タイトル：</Typography>
            {editImgTitle ?
              (<><TextField
                autoFocus
                required
                size="small"
                label=""
                type="text"
                variant="standard"
                inputProps={{ maxLength: 50 }}
                value={imgTitle}
                onChange={(e) => { setImgTitle(e.target.value) }}
              />
                <IconButton aria-label="save" size="small" sx={{ color: "green" }}
                  onClick={() => {
                    if (imgTitle.trim() === siteImg.ImageTitle) {
                      setEditImgTitle(false);
                      return;
                    } handleChange();
                  }}>
                  <FontAwesomeIcon icon={faCheck} />
                </IconButton>
                <IconButton aria-label="cancel" size="small" sx={{ color: "gray" }}
                  onClick={() => { setEditImgTitle(false); setImgTitle(siteImg.ImageTitle); }}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>

              </>
              ) : (<>
                <Typography variant='body1'>{imgTitle}</Typography>
                <IconButton aria-label="edit" size="small" sx={{ color: "green" }}
                  onClick={() => { setEditImgTitle(true); }}>
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
              </>)}

          </Stack>
          <Stack direction="row" alignItems="end" mt={"2px"}>
            <Typography variant='body2' sx={{ minWidth: "104px" }}>アップロード者：</Typography>
            <Typography variant='body1'>{siteImg.UploadedUserName}</Typography>
          </Stack>
          <Stack direction="row" alignItems="end" >
            <Typography variant='body2' sx={{ minWidth: "40px", }}>日付：</Typography>
            {editImgDate ? (<>
              <TextField
                margin="dense"
                size='small'
                label=""
                type="date"
                fullWidth
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                value={imgDate}
                onChange={(e) => { setImgDate(e.target.value) }}
              />
              <IconButton aria-label="save" size="small" sx={{ color: "green" }}
                onClick={() => {
                  if (imgDate.trim() === siteImg.ImageDate) {
                    setEditImgDate(false);
                    return;
                  } handleChange();
                }}>
                <FontAwesomeIcon icon={faCheck} />
              </IconButton>
              <IconButton aria-label="cancel" size="small" sx={{ color: "gray" }}
                onClick={() => { setEditImgDate(false); setImgDate(dayjs(siteImg.ImageDate).format("YYYY-MM-DD")); }}>
                <FontAwesomeIcon icon={faXmark} />
              </IconButton>
            </>) : (<>
              <Typography variant='body1'>{dayjs(siteImg.ImageDate).format("YYYY/MM/DD")}</Typography>
              <IconButton aria-label="edit" size="small" sx={{ color: "green" }}
                onClick={() => { setEditImgDate(true); }}>
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </>)}

          </Stack>
          <Stack direction="row" alignItems="start" mt={"2px"} >
            <Typography variant='body2' sx={{ minWidth: "40px", mt: "2px" }}>メモ：</Typography>
            {editImgMemo ? (<>
              <TextField
                margin="dense"
                label=""
                type="text"
                fullWidth
                variant="standard"
                multiline
                maxRows={3}
                inputProps={{ maxLength: 300 }}
                value={imgMemo}
                onChange={(e) => { setImgMemo(e.target.value) }}
              />
              <IconButton aria-label="save" size="small" sx={{ color: "green" }}
                onClick={() => {
                  if (imgMemo.trim() === siteImg.ImageMemo) {
                    setEditImgMemo(false);
                    return;
                  } handleChange();
                }}>
                <FontAwesomeIcon icon={faCheck} />
              </IconButton>
              <IconButton aria-label="cancel" size="small" sx={{ color: "gray" }}
                onClick={() => { setEditImgMemo(false); setImgMemo(siteImg.ImageMemo); }}>
                <FontAwesomeIcon icon={faXmark} />
              </IconButton>
            </>) : (<>
              <Typography variant='body1' >{siteImg.ImageMemo}</Typography>
              <IconButton aria-label="edit" size="small" sx={{ color: "green" }}
                onClick={() => { setEditImgMemo(true); }}>
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </>)}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 2, pb: 1 }}>
          <Button sx={{ mr: 1 }} onClick={() => { handleClose() }}>キャンセル</Button>
          <Button color='secondary' variant='contained' onClick={() => { handleDelete() }}>削除</Button>
        </DialogActions>

      </Dialog >
    )}
  </>
}

export default ModalShowImage;
