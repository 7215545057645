import axiosClient from "./axiosClient";

const postalCodeApi = {
    getPostalCode(params) {
        const url = '/postal-code';
        return axiosClient.get(url, { params: params });
    },
}

export default postalCodeApi;
