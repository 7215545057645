import React, { createRef, useEffect, useState } from 'react'
import { ButtomClose, ButtonInput, Content, FormBox, FormContent, FormFooter, FormHeader, FormSearch, InputText, Wrapper } from './styles'
import ItemSelect from '../ItemSelect';
import SearchBox from 'components/SearchBox';

const PostalCodeSelectBox = ({ show, setShow, resultList, renderItem, selectItem }) => {
    const refTop = createRef();

    const handleSelect = (item) => {
        selectItem(item);
        setShow(false);
    }

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [resultList, refTop])

    return (
        <>
            <FormSearch show={show}>
                <FormBox>
                    <FormHeader>
                        {"郵便番号を選択してください"}
                    </FormHeader>
                    {/* <SearchBox placeholder={"住所"} setTermSearch={setTermSearch} /> */}
                    <FormContent ref={refTop}>
                        {resultList?.length > 0 ? (
                            <>
                                {resultList.map((item, index) => <ItemSelect key={index} handleSelect={() => { handleSelect(item); }}>{renderItem(item)}</ItemSelect>)}
                            </>
                        ) : (<>データなし</>)}
                    </FormContent>
                    <FormFooter>
                        <ButtomClose onClick={() => setShow(false)}>閉じる</ButtomClose>
                    </FormFooter>
                </FormBox>
            </FormSearch>
        </>
    )
}

export default PostalCodeSelectBox