import React, { useEffect, useState } from 'react';
import { Wrapper, Content, Line, MLabel } from './styles';
import { Box, Button, IconButton, Stack, Typography, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Compressor from 'compressorjs';
import ModalUploadSingleImage from '../ModalUploadImages/ModalUploadSingleImage';
import ModalUploadMultipleImages from '../ModalUploadImages/ModalUploadMultipleImages';
import siteApi from 'api/siteApi';
import SiteImagesList from '../SiteImgesList';
import ModalShowImage from '../ModalShowImage';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const MaxAllowedSize = 5 * 1024 * 1024; // 5MB
const compressImage = (file) => {
    return new Promise((resolve, reject) => {
        const qualityNum = file.size > MaxAllowedSize ? 0.7 : 1;
        new Compressor(file, {
            quality: qualityNum,
            convertTypes: ['image/png', 'image/webp', 'image/jpeg'],
            convertSize: 5000000,
            success(result) {
                resolve({ fileName: file.name, file: result, url: URL.createObjectURL(result) });
            },
            error(err) {
                reject(err);
            },
        });
    });
};



const SiteImageDetails = ({ siteID, setIsLoading }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [objSite, setObjSite] = useState();
    const [siteImagesList, setSiteImagesList] = useState([]);
    const [selectedImageID, setSelectedImageID] = useState(0);

    useEffect(() => {
        (async function getSiteData() {
            if (!siteID) return;
            try {
                setIsLoading(true);
                /**Call Api */
                const params = {
                    siteID: siteID || ''
                }
                const [dtResSiteDetails, dtResSiteImages] = await Promise.all([siteApi.getSiteDetails(params), siteApi.getSiteImages(params)]);
                const site = dtResSiteDetails?.data['site'] || {};
                const siteImgs = dtResSiteImages?.data['dtSiteImages'] || [];
                setObjSite(site);
                setSiteImagesList(siteImgs);
            } catch (err) {
                alert(err);
            }
            finally {
                setIsLoading(false);
            }
        })()
    }, [setIsLoading, siteID]);


    const handleCapture = async (target, setSource) => {
        if (!target.files || target.files.length < 1) return;
        const filesArray = Array.from(target.files);
        const compressedFiles = await Promise.all(filesArray.map(file => compressImage(file)));
        setSelectedImages(compressedFiles);
    };

    const hanleUpload = async (imgTitle = "", imgDate = "", imgMemo = "") => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("sID", objSite.SiteID);
            formData.append("iT", imgTitle.trim());
            formData.append("iD", imgDate.trim());
            formData.append("iM", imgMemo.trim());
            selectedImages.forEach(f => {
                formData.append("fileUploads[]", f.file);
            })

            await siteApi.registerSiteImages(formData);
            const { data } = await siteApi.getSiteImages({ siteID })
            if ('dtSiteImages' in data) setSiteImagesList(data["dtSiteImages"]);
            setSelectedImages([]);
        } catch (error) {
            //TODO: Show Error
        } finally {
            setIsLoading(false);
        }
    }

    const handleImgDelete = async () => {
        if (!selectedImageID || !siteID) return;
        try {
            setIsLoading(true);
            const params = {
                imgID: selectedImageID
            }
            await siteApi.deleteSiteImage(params);
            const { data } = await siteApi.getSiteImages({ siteID })
            if ('dtSiteImages' in data) setSiteImagesList(data["dtSiteImages"]);
            setSelectedImageID(0);
        } catch (error) {
            //TODO: Show Error
        } finally {
            setIsLoading(false);
        }
    }

    const handleUpdateMainImage = async (target) => {
        if (!target.files || target.files.length !== 1 || !objSite.SiteID) return;
        try {
            setIsLoading(true);
            const uploadedFile = Array.from(target.files)[0];
            const compressedFiles = await compressImage(uploadedFile);
            console.log(compressedFiles);
            setIsLoading(true);
            const formData = new FormData();
            formData.append("siteID", objSite.SiteID);
            formData.append("imgName", objSite.ImageName);
            formData.append("fileUploads[]", compressedFiles.file);

            const { data } = await siteApi.updateSiteMainImage(formData);
            if ('site' in data) setObjSite(data["site"]);
        } catch (error) {
            //TODO: Show Error
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <>
            {!!objSite?.SiteID && (
                <Wrapper>
                    <Content>
                        <Box>
                            <Line>
                                <MLabel>現場：</MLabel>
                                {objSite.SiteName || '―'}
                            </Line>
                            <Line>
                                <MLabel>住所：</MLabel>
                                <a href={`https://www.google.co.jp/maps/place/${objSite.AddressPrefecture || ''}${objSite.AddressCity || ''}${objSite.AddressNumber || ''}${objSite.Building || ''}`}
                                    target="_blank" rel="noopener noreferrer">
                                    〒{objSite.PostalCode || '―'}　{objSite.AddressPrefecture || ''}{objSite.AddressCity || ''}{objSite.AddressNumber || ''}{objSite.Building || ''}
                                </a>
                            </Line>
                        </Box>
                        <Box sx={{ textAlign: "center", mt: 1 }} >
                            {!!objSite.ImageUrl && !!objSite.ImageName && (<Box component={"img"} sx={{
                                width: "70vw",
                                maxWidth: "500px",
                            }} alt='現場メイン画像' src={objSite.ImageUrl} />
                            )}
                        </Box>
                        <Box sx={{ textAlign: "center", pb: 1 }}>
                            <Button component="label" sx={{ p: 0 }}>{!!objSite.ImageUrl ? "メイン画像変更>>" : "メイン画像投稿"}
                                <VisuallyHiddenInput type="file"
                                    accept="image/*"
                                    value={""}
                                    onChange={(event) => { handleUpdateMainImage(event.target) }}
                                />
                            </Button>
                        </Box>

                        <Box>
                            <Stack direction={"row"} sx={{ backgroundColor: "#d3e8d8", px: 1, justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>画像一覧</Typography>
                                <IconButton component="label" sx={{ color: "rgba(0, 149, 39, 1)", fontSize: "32px" }}>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                    <VisuallyHiddenInput type="file"
                                        accept="image/*"
                                        value={""}
                                        onChange={(event) => { handleCapture(event.target) }}
                                        multiple />
                                </IconButton>
                            </Stack>
                        </Box>
                        <SiteImagesList imgList={siteImagesList} handleSelectImg={(imgID) => { setSelectedImageID(imgID) }} />
                    </Content>
                    {/**** Upload Image ***/}
                    {!!selectedImages && !!selectedImages.length && (<>
                        {selectedImages.length === 1 ?
                            (<ModalUploadSingleImage fileImg={selectedImages}
                                handleClose={() => { setSelectedImages([]) }}
                                hanleSave={hanleUpload}
                            />)
                            : (<ModalUploadMultipleImages imgList={selectedImages}
                                handleClose={() => { setSelectedImages([]) }}
                                handleSave={hanleUpload}
                            />)}
                    </>)}
                    {!!selectedImageID && (<ModalShowImage siteImgID={selectedImageID}
                        setIsLoading={setIsLoading}
                        handleClose={() => { setSelectedImageID(0) }}
                        handleDelete={handleImgDelete}
                    />
                    )}
                </Wrapper >
            )}
        </>
    )
}

export default SiteImageDetails;