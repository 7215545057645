import React, { useEffect, useState } from "react";
import { BargainButton, Body, Button, Header, Wrapper } from "./styles";
import ImgLogo from "assets/images/logo.png";
import bargainApi from "api/bargainApi";
import Loading from "components/Loading";

const Menu = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bargainCount, setBargainCount] = useState(0);

  useEffect(() => {
    (async function GetBargainCount() {
      try {
        setIsLoading(true);
        /**Call Api **/
        const dtResponse = await bargainApi.getBargainCount();
        const dtCount = dtResponse?.data?.bargainCount ?? 0;
        setBargainCount(dtCount);
        setIsLoading(false);

      } catch (err) {
        alert(err);
        setIsLoading(false);
      }
    })();
  }, [])

  return <Wrapper>
    <Loading isLoading={isLoading} />
    <Header>
      <img className="logo" src={ImgLogo} alt='Morien Logo' />
    </Header>
    <Body>
      {bargainCount > 0 && (<BargainButton to={'/bargain'}>セール！</BargainButton>)}
      <Button to={'/goods'}>新規注文<div style={{ fontSize: "0.9rem", lineHeight: "normal" }}>商品検索（お気に入り）</div></Button>
      <Button to={'/goods-all'}>新規注文<div style={{ fontSize: "0.9rem", lineHeight: "normal" }}>商品検索（全）</div></Button>
      <Button to={'/order'}>注文履歴</Button>
      <Button to={'/site'}>現場検索</Button>
      <Button to={'/site-new'}>現場登録</Button>
      <Button to={'/site-image'}>現場画像</Button>
      <Button to={'/img-post'}>写真投稿</Button>
    </Body>
  </Wrapper>;
};

export default Menu;
