import React from 'react';
import { Box, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

const SiteImagesList = ({ imgList, handleSelectImg }) => {
  return (
    <Box px={1} sx={{ textAlign: "center" }}>
      <ImageList sx={{ overflowY: "unset", mx: { xs: 0, md: 5, lg: 20 } }} cols={2} rowHeight={"auto"} >
        {imgList.map((item, index) => (
          <Stack key={`${item.SiteImageID}-${index}`} sx={{ justifyContent: "start", alignItems: "center" }}>
            <ImageListItem>
              <Stack direction="row" spacing={2} pl={"2px"}>
                <Typography sx={{
                  whiteSpace: 'nowrap',
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "46vw",
                  maxWidth: "400px",
                  textAlign: "left"
                }}>{dayjs(item.ImageDate).format("YYYY/MM/DD") + "　" + item.ImageTitle}</Typography>
              </Stack>
              <Box
                component="img"
                srcSet={item.ImageUrl}
                src={item.ImageUrl}
                alt={item.ImageName}
                loading="lazy"
                sx={{ width: "100%", maxWidth: "500px", height: "100%", objectFit: "contain" }}
                onClick={() => handleSelectImg(item.SiteImageID)}
              />
            </ImageListItem>
          </Stack>
        ))}
      </ImageList>
    </Box>
  )
}

export default SiteImagesList
