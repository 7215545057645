import { useEffect, useState } from "react";
import siteApi from "api/siteApi";

export const useSite = () => {

    const [termSearch, setTermSearch] = useState({});
    const [siteList, setSiteList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //search site
    useEffect(() => {
        const searchSite = async (termSearch) => {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    siteTypeID: termSearch?.siteTypeID || 1,
                    siteDateID: termSearch?.siteDateID || 0,
                    dateFrom: termSearch?.dateFrom,
                    dateTo: termSearch?.dateTo,
                    txtSearch: termSearch?.txtSearch || '',
                }
                const dtResponse = await siteApi.getSite(params);
                const dtSite = dtResponse?.data['dtSite'] ?? [];
                setSiteList(dtSite);
                setIsLoading(false);
            } catch (err) {
                alert(err);
                setIsLoading(false);
            }
        }
        searchSite(termSearch);
    }, [termSearch])

    return { termSearch, setTermSearch, siteList, isLoading, setIsLoading };

}