import { MESSAGE } from 'Common';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position:fixed;
    top:0;
    bottom: 0;
    background-color: rgba(230,230,230, 0.5);
    z-index: 99998;   
    .btn-yes{
        background-color: rgba(0, 149, 39, 0.8);
        
        :hover {
            transform: scale(1.2);
            color: #000;
            background-color: rgba(0, 149, 39, 1);

        }
    } 
`;

export const Container = styled.div`
    width: 80%;
    max-width: 450px;
    height: fit-content;
    max-height: 70%;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0;
    background-color: ${props => props.msgType === MESSAGE.ERROR ? "#7e7e7e" : "#45b0e6"};

`;

export const Title = styled.div`
    flex: 1;
    padding-left: 15px;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
`;

export const Body = styled.div`
    color: ${props => props.msgType === MESSAGE.ERROR ? "red" : "black"};
    padding: 20px 10px;

    p {
        padding: 0;
        margin: 0;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 0;
`;

export const Button = styled.button`
    padding: 5px 15px;
    border: 0;
    font-size: 0.9rem;
    appearance: button;
    -webkit-appearance: button;
    background-color: #808080;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    :active{
        background-color: red;
    }
`