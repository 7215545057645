import axiosClient from "./axiosClient";

const siteApi = {

    getSite(params) {
        const url = '/site';
        return axiosClient.get(url, { params: params });
    },
    getSiteDetails(params) {
        const url = '/site/details';
        return axiosClient.get(url, { params: params });
    },
    getSiteSalesGoods(params) {
        const url = '/site/sales-goods';
        return axiosClient.get(url, { params: params });
    },
    registerSite(params) {
        const url = '/site/register';
        return axiosClient.post(url, params);
    },
    updateSite(params) {
        const url = '/site/update';
        return axiosClient.post(url, params);
    },
    deleteSite(params) {
        const url = '/site/delete';
        return axiosClient.post(url, params);
    },

    getSiteImages(params) {
        const url = '/site/images';
        return axiosClient.get(url, { params: params });
    },
    registerSiteImages(params) {
        const url = '/site/images';
        return axiosClient.post(url, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    getSiteImageDetails(params) {
        const url = '/site/images/details';
        return axiosClient.get(url, { params: params });
    },
    deleteSiteImage(params) {
        const url = '/site/images/del';
        return axiosClient.post(url, params);
    },
    updateSiteMainImage(params) {
        const url = '/site/main-img';
        return axiosClient.post(url, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    updateSiteImages(params) {
        const url = '/site/images/update';
        return axiosClient.post(url, params);
    },

}

export default siteApi;
