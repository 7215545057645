import { Box, Button, Dialog, DialogActions, DialogContent, ImageList, ImageListItem } from '@mui/material';
import React from 'react';

const ModalUploadMultipleImages = ({ imgList, handleClose, handleSave }) => {

  return (
    <Dialog open={!!imgList.length} onClose={() => { }}    >
      <DialogContent>
        {/* Show images */}
        <ImageList sx={{ width: "100%", height: "70%" }} cols={2} rowHeight={120}>
          {imgList.map((item, index) => (
            <ImageListItem key={`${item.file}-${index}`}>
              <Box
                component="img"
                srcSet={item.url}
                src={item.url}
                alt={item.fileName}
                loading="lazy"
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 1 }} onClick={() => { handleClose() }}>キャンセル</Button>
        <Button variant='contained' onClick={() => { handleSave() }}>保存</Button>
      </DialogActions>

    </Dialog>
  )
}

export default ModalUploadMultipleImages;