import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';


const ModalUploadSingleImage = ({ fileImg, handleClose, hanleSave }) => {
  const [imgTitle, setImgTitle] = useState("");
  const [imgDate, setImgDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [imgMemo, setImgMemo] = useState();

  return (
    <Dialog open={!!fileImg[0].fileName}>
      <DialogContent>
        {/* Show images */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box component={"img"}
            sx={{ maxHeight: '90%', maxWidth: '90%' }}
            alt='現場画像' src={fileImg[0].url} />
        </Box>
        <Box>
          <TextField
            autoFocus
            required
            margin="dense"
            label="タイトル"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={imgTitle}
            onChange={(e) => { setImgTitle(e.target.value) }}
          />
          <TextField
            margin="dense"
            label="日付"
            type="date"
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={imgDate}
            onChange={(e) => { setImgDate(e.target.value) }}
          />
          <TextField
            margin="dense"
            label="メモ"
            type="text"
            fullWidth
            variant="standard"
            multiline
            maxRows={3}
            inputProps={{ maxLength: 300 }}
            value={imgMemo}
            onChange={(e) => { setImgMemo(e.target.value) }}
          />
        </Box>

      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 1 }} onClick={() => { handleClose() }}>キャンセル</Button>
        <Button variant='contained' onClick={() => { hanleSave(imgTitle, imgDate, imgMemo) }}>保存</Button>
      </DialogActions>

    </Dialog>
  )
}

export default ModalUploadSingleImage;