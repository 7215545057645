import React, { useEffect, useRef, useState } from "react";
import HeaderBox from "components/HeaderBox";
import Loading from "components/Loading";
import SiteItem from "./SiteItem";
import { Wrapper, Content, DataEmpty } from './styles'
import { useSite } from "./useSite";
import SearchBox from "components/SearchBox";
import { TermSite } from "components/TermSearch";
import ButtonBack from "components/ButtonBack";
import SiteImageDetails from "./SiteImageDetails";

const SiteImage = () => {
    const { setTermSearch, siteList, isLoading, setIsLoading } = useSite();
    const [selectedSiteID, setSelectedSiteID] = useState(0);

    const refTop = useRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [siteList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title={selectedSiteID > 0 ? "現場画像リスト" : "現場画像管理"} haveCart />
            <div style={{ position: "relative" }}>
                <SearchBox setTermSearch={setTermSearch} placeholder={"現場名"}>
                    <TermSite setTermSearch={setTermSearch} />
                </SearchBox>
                <Content ref={refTop}>
                    {
                        siteList.length > 0 ? siteList.map((item) => {
                            return (
                                <SiteItem key={item.SiteID} item={item} onSelectSite={() => { setSelectedSiteID(item.SiteID) }} />
                            );
                        }) : <DataEmpty>データなし</DataEmpty>
                    }
                </Content>
                {selectedSiteID > 0 && <SiteImageDetails siteID={selectedSiteID} setIsLoading={setIsLoading} />}
                {selectedSiteID > 0 && <ButtonBack onHandleBack={() => { setSelectedSiteID(0) }} />}

            </div>
        </Wrapper>
    )
}

export default SiteImage;